import React from "react"
import { Link as GatsbyLink } from "gatsby"
import styled from "styled-components"

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;

  h1 {
    font-size: 10rem;
  }

  h1,
  h2,
  p {
    line-height: 1;
    margin-bottom: 1rem;
  }
`

const StyledGatsbyLink = styled(GatsbyLink)`
  text-decoration: none;
  color: #b01f09;
  font-size: 1rem;
  font-weight: 600;
  margin-top: 1rem;

  &:hover {
    text-decoration: underline;
  }
`

export default function Page404({ location }) {
  return (
    <StyledWrapper>
      <h1>404</h1>
      <h2>Page not found</h2>
      <p>{location.pathname} page does not exist</p>
      <StyledGatsbyLink to="/">Back to the home page</StyledGatsbyLink>
    </StyledWrapper>
  )
}
